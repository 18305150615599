//SETTLEMENT_TABLE_HEADER.schema
import { _t } from "@/tools/Utils";

export const SETTLEMENT_TABLE_HEADER = {
  headers: [
    {
      text: _t("attribute.settlementType"),
      align: "start",
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.teacherLessonType"),
      align: "start",
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.fullName"),
      align: "start",
      sortable: false,
      value: "teacherFullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.createMoment"),
      align: "start",
      sortable: false,
      value: "createMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    // {
    //   text: _t("attribute.fromTime"),
    //   align: "start",
    //   sortable: false,
    //   value: "from",
    //   class: "text--disabled text-subtitle-1",
    //   cellClass: "",
    // },
    {
      text: _t("attribute.month"),
      sortable: false,
      value: "to",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.amount"),
      sortable: false,
      value: "amount",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.settlementDescription"),
      sortable: false,
      value: "description",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.totalMonthlyTaught"),
      sortable: false,
      value: "totalTeachHour",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.edit"),
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.delete"),
      sortable: false,
      value: "delete",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
};
