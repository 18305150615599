<template>
  <v-row>
    <v-col v-if="['Admin', 'TeamLeader', 'FinancialAdmin'].includes(role)">
      <UsersTableSection
        role="Teacher"
        @salary="addSalary"
        @report="getTeacherReport"
        @export="exportAllSettlements"
        @sum="summation"
        @update="update"
      />
    </v-col>
    <v-col
      cols="12"
      v-if="['Admin', 'FinancialAdmin', 'TeamLeader'].includes(role)"
    >
      <SettlementTable
        ref="settlement"
        @edit="editSalary"
        @delete="deleteSettlement"
      />
    </v-col>
    <AddSalary
      :is-show-modal="addSalaryModal"
      :user="user"
      @close="close"
      @update="update"
      :salary="salary"
    />
    <v-dialog v-model="showSum" width="800">
      <v-card v-if="details">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $_t("attribute.salaryHistory") }}
        </v-card-title>

        <v-card-title>
          {{ `Summation: ${details.totalAmount}` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title>{{ $_t("attribute.details") + " :" }}</v-card-title>
        <v-card-title
          class="pb-5"
          v-for="s in details.settlements"
          :key="s.settlementId"
        >
          <v-row>
            <v-col cols="12">
              {{
                `${$_t("attribute.createMoment")}: ${$_date(
                  s.createMoment,
                  "long"
                )}`
              }}
            </v-col>
            <v-col cols="12">
              {{
                `${$_t("attribute.fromTime")}: ${$_date(
                  s.fromTime * 1000,
                  "long"
                )}`
              }}
            </v-col>
            <v-col cols="12">
              {{
                `${$_t("attribute.toTime")}: ${$_date(s.toTime * 1000, "long")}`
              }}
            </v-col>
            <v-col cols="12">
              {{ `${$_t("attribute.amount")}: ${s.amount}` }}
            </v-col>
            <v-col cols="12">
              {{
                `${$_t("attribute.baseType")}: ${$_t(
                  `attribute.${s.teacherLessonType}`
                )}`
              }}
            </v-col>
            <v-col cols="12">
              {{ "-" + $_t("attribute.classroomsDetail") + " :" }}
              <v-card-title
                v-for="(d, index) in s.classroomsDetail"
                :key="index"
              >
                <v-row>
                  <v-col cols="12">
                    {{
                      `${$_t("attribute.classroomName")}: ${d.classroomName}`
                    }}
                  </v-col>
                  <v-col cols="12">
                    {{
                      `${$_t("attribute.baseType")}: ${$_t(
                        `attribute.${d.courseName}`
                      )}`
                    }}
                  </v-col>
                  <v-col cols="12">
                    {{
                      `${$_t("attribute.classroomType")}: ${d.classroomType}`
                    }}
                  </v-col>
                  <v-col cols="12">
                    {{ `${$_t("attribute.teachHour")}: ${d.teachHour}` }}
                  </v-col>
                  <v-col cols="12">
                    {{ `${$_t("attribute.teachFee")}: ${d.teachFee}` }}
                  </v-col>
                  <v-col cols="12" v-if="role !== 'TeamLeader'">
                    {{
                      `${$_t("attribute.studentsTotalPayForClassroom")}: ${
                        d.studentsTotalPayForClassroom
                      }`
                    }}
                  </v-col>
                  <v-col cols="12" v-if="role !== 'TeamLeader'">
                    {{ "--" + $_t("attribute.classroomStudents") + " :" }}
                  </v-col>
                  <v-col cols="12" v-if="role !== 'TeamLeader'">
                    <v-card-text
                      v-for="(stu, index) in d.classroomStudents"
                      :key="index"
                      class="d-inline text-body-1"
                    >
                      <div>
                        {{ `${$_t("attribute.fullName")}: ${stu.fullName}` }}
                        <br />
                        {{
                          `${$_t("attribute.payedAmount")}: ${stu.payedAmount}`
                        }}
                        <br />
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showSum = false">
            {{ $_t("attribute.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showReport" width="1000">
      <v-card v-if="report">
        <v-card-title class="text-h5 grey lighten-2">
          {{
            `${report.teacherName} ${$_t("attribute.report")} to ${$_date(
              report.toTime,
              "long"
            )}`
          }}
        </v-card-title>
        <v-card-title>
          {{
            `${$_t("attribute.totalPayedAmount")}: ${report.totalPayedAmount}`
          }}
        </v-card-title>
        <v-card-title>
          {{
            `${$_t("attribute.totalShouldPayToTeacher")}: ${
              report.totalShouldPayToTeacher
            } `
          }}
        </v-card-title>
        <v-card-title>
          {{ `${$_t("attribute.totalTeachHour")}: ${report.totalTeachHour}` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title v-for="(r, index) in report.report" :key="index">
          {{
            `${$_t("attribute.fullName")}: ${r.studentName} - ${$_t(
              "attribute.baseType"
            )}: ${$_t(`attribute.${r.courseName}`)}`
          }}
          <br />
          {{
            `${$_t("attribute.monthlyTeaching")}: ${
              r.monthlyTeachingHour
            } - ${$_t("attribute.teachFee")}: ${r.teachFee} - ${$_t(
              "attribute.shouldPayToTeacher"
            )}: ${r.shouldPayToTeacher} - ${$_t("attribute.level")}: ${$_t(
              `attribute.${r.courseCategory}`
            )}`
          }}
          <br />
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showReport = false">
            {{ $_t("attribute.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UsersTableSection from "@/packages/finance/components/UsersTableSection";
import SettlementTable from "@/packages/finance/components/settlement/SettlementTable";
import AddSalary from "@/packages/finance/components/settlement/AddSalary";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "settlement",
  components: {
    AddSalary,
    SettlementTable,
    UsersTableSection,
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
  },
  data() {
    return {
      addSalaryModal: false,
      user: null,
      salary: null,
      showSum: false,
      showReport: false,
      report: null,
      details: null,
    };
  },
  methods: {
    async addSalary(item) {
      this.user = item;
      this.addSalaryModal = true;
    },
    async editSalary(item) {
      this.salary = item;
      await this.$actions.getClassroomsOfUser({
        publicKey: item.teacherPublicKey,
        dispatch: "class/setClassroomsOfUser",
      });
      this.addSalaryModal = true;
    },
    async deleteSettlement(item) {
      const res = await this.$actions.deleteSettlement({
        settlementId: item.settlementId,
      });
      if (res) await this.$refs.settlement.init({});
    },
    async getTeacherReport(item) {
      console.log(item, "getTeacherReport");
      const res = await this.$actions.getTeacherSettlementsReport({
        teacherPublicKey: item.publicKey,
      });
      if (!res) return;
      this.report = res;
      console.log(res);
      this.showReport = true;
    },
    close() {
      this.user = null;
      this.salary = null;
      this.addSalaryModal = false;
    },
    update() {
      this.$refs.settlement.init({});
    },
    async summation(item) {
      this.details = await this.$actions.getSettlementHistory({
        teacherPublicKey: item.publicKey,
      });
      this.showSum = true;
    },
    async exportAllSettlements(item, date) {
      await api
        .get(
          urls.qs("exportSettlements", {
            teacherPublicKey: item.publicKey,
            year: date ? moment(date).year() : null,
            month: date ? moment(date).month() + 1 : null,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(res, `${item.fullName} settlements.xlsx`);
        });
    },
  },
};
</script>

<style scoped></style>
