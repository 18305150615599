<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          salary
            ? $_t("attribute.editSettlement")
            : $_t("attribute.addSettlement")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
          <v-row justify="center" v-if="guide" class="py-4">
            <p>
              {{
                `${$_t("attribute.classroomTotalCost")}: ${
                  guide.classroomTotalCost
                } | ${$_t("attribute.shouldPay")}: ${guide.shouldPay} | ${$_t(
                  "attribute.totalGained"
                )}: ${guide.totalGained}`
              }}
            </p>
            <p>
              {{
                `${$_t("attribute.totalMonthlyTeachingHour")}: ${
                  guide.totalMonthlyTeachingHour
                } h | ${$_t("attribute.totalTeachHour")}: ${
                  guide.totalTeachHour
                } h`
              }}
            </p>

            <br />
            <div class="red--text" v-if="lowerCost">
              {{ `${$_t("attribute.lowAmount")}: ${lowerCost}` }}
            </div>
          </v-row>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                :disabled="is_loading || $v.$error"
              >
                {{ salary ? $_t("attribute.edit") : $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_SALARY } from "@/packages/finance/schema/ADD_SETTLEMENT.schema";
import { decimal, required } from "vuelidate/lib/validators";
export default {
  name: "AddSalary",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    user: {
      required: true,
    },
    salary: {
      default: null,
    },
  },
  data() {
    return {
      schema: ADD_SALARY.schema,
      form: ADD_SALARY.model,
      guide: null,
      lowerCost: null,
      teacherPublicKey: null,
    };
  },
  validations() {
    let form = {
      settlementType: { required },
      fromTime: {},
      toTime: {
        required,
      },
      amount: { required, decimal },
      teacher: { required },
      teacherLessonType: {},
      settlementDescription: {},
    };
    if (this.form.settlementType === "TeachingSalary") {
      form.fromTime = {};
      form.settlementDescription = {};
      form.teacherLessonType = { required };
    } else {
      form.fromTime = { required };
      form.settlementDescription = { required };
      form.teacherLessonType = {};
    }

    return {
      form: { ...form },
    };
  },
  watch: {
    isShowModal(val) {
      if (val) {
        if (this.salary) {
          this.teacherPublicKey = this.salary.teacherPublicKey;
          this.form.teacherLessonType = this.salary.teacherLessonType;
          this.form.settlementType = this.salary.settlementType;
          this.form.amount = this.salary.amount;
          this.form.fromTime = this.$_date(this.salary.fromTime * 1000, "ISO");
          this.form.teacher = this.salary.teacherFullName;
          this.form.settlementDescription = this.salary.settlementDescription;
          this.form.toTime = this.$_date(this.salary.toTime * 1000, "ISO");
        } else {
          this.form.teacher = this.user.fullName || null;
        }
      }
    },
    async "form.toTime"(val) {
      if (!val) return;
      if (this.form.settlementType === "OtherPayments") return;
      this.guide = await this.$actions.getGuideForCreateSettlement({
        toTime:
          new Date(`${this.form.toTime.split("T")[0]}T23:59:59`).getTime() /
          1000,
        teacherPublicKey: this.user?.publicKey
          ? this.user.publicKey
          : this.teacherPublicKey,
        teacherLessonType: this.form.teacherLessonType,
      });
    },
    async "form.amount"(val) {
      if (!val) return;
      if (this.form.settlementType === "OtherPayments") return;
      this.lowerCost = await this.$actions.calcClassroomBalance({
        teacherPublicKey: this.user?.publicKey || this.teacherPublicKey,
        toTime:
          new Date(`${this.form.toTime.split("T")[0]}T23:59:59`).getTime() /
          1000,
        amount: val,
        teacherLessonType: this.form.teacherLessonType,
      });
    },
    "form.settlementType"(val) {
      if (val === "OtherPayments") {
        this.schema[
          this.getIdWithName(this.schema, "settlementDescription")
        ].disabled = false;
        this.schema[
          this.getIdWithName(this.schema, "fromTime")
        ].disabled = false;
        this.schema[
          this.getIdWithName(this.schema, "teacherLessonType")
        ].disabled = true;
      } else if (val === "TeachingSalary") {
        this.schema[
          this.getIdWithName(this.schema, "settlementDescription")
        ].disabled = true;
        this.schema[
          this.getIdWithName(this.schema, "fromTime")
        ].disabled = true;
        this.schema[
          this.getIdWithName(this.schema, "teacherLessonType")
        ].disabled = false;
        this.form.settlementDescription = null;
        this.form.fromTime = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;

        let res = null;
        if (this.salary) {
          res = await this.$actions.editSalaryToTeacher({
            settlementId: this.salary.settlementId,
            settlementDescription: this.salary?.settlementDescription || null,
            toTime: new Date(this.form.toTime).getTime() / 1000,
            fromTime: new Date(this.form.fromTime).getTime() / 1000,
            amount: parseFloat(this.form.amount),
            publicKey: this.salary?.publicKey || this.teacherPublicKey,
            teacherLessonType: this.form.teacherLessonType,
          });
        } else {
          res = await this.$actions.addSalaryToTeacher({
            ...this.form,
            fromTime: new Date(this.form.fromTime).getTime() / 1000,
            toTime: new Date(this.form.toTime).getTime() / 1000,
            amount: parseFloat(this.form.amount),
            publicKey: this.user.publicKey,
            teacherLessonType: this.form.teacherLessonType,
          });
        }

        if (res) {
          this.$emit("update");
        }
        this.close();
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form.fromTime = null;
      this.form.toTime = null;
      this.form.settlementDescription = null;
      this.form.settlementType = null;
      this.form.amount = null;
      this.form.teacher = null;
      this.teacherPublicKey = null;
      this.guide = null;
      this.lowerCost = null;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
