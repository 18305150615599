<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="4" sm="4" class="pt-5">
        <TopTitle :title="$_t('attribute.settlements')" />
      </v-col>
      <v-spacer />
      <v-col cols="3" v-if="role !== 'TeamLeader'">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$_t('attribute.exportGeneralFinancial')"
              readonly
              outlined
              clearable
              dense
              v-bind="attrs"
              v-on="on"
              background-color="#fff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            color="primary"
            elevation="0"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              {{ $_t("attribute.cancel") }}
            </v-btn>
            <v-btn color="primary" @click="exportFinancialGeneral">
              {{ $_t("attribute.export") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-select
          hide-details
          outlined
          :label="$_t('attribute.filter_by_teacher')"
          dense
          background-color="#fff"
          :items="teachers"
          v-model="teacherPublicKey"
          @change="init({})"
          clearable
          :item-text="
            (item) =>
              `${item.fullName} | ${item.nickName}| ${item.state} | ${item.baseType.map((t) =>
                $_t(`attribute.${t}`)
              )}`
          "
          item-value="publicKey"
        >
          <template v-slot:prepend-item>
            <div class="px-3 py-2">
              <v-text-field
                outlined
                :label="$_t('attribute.searchTeacher')"
                dense
                hide-details
                @input="fetchTeachers"
                v-model="teacherSearch"
              />
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="pageInfo.page = 1;init({ search: $event })"
          :headers="headers"
          :items="settlements"
          :page-info="pageInfo"
          :loading="is_loading"
          hide-search
        >
          <template v-slot:top-right>
            <v-chip class="" color="primary" label outlined>
              {{ `${$_t(`attribute.sumOfAllSettlements`)}: ${sumOfAll}` }}
            </v-chip>
          </template>
          <template v-slot:createMoment="{ item }">
            {{ $_date(item.createMoment) }}
          </template>
          <!--          <template v-slot:totalPay="{ item }">-->
          <!--            <span v-if="role !== 'TeamLeader'">{{-->
          <!--              item.studentsTotalPayForToTime-->
          <!--            }}</span>-->
          <!--          </template>-->
          <template v-slot:fullName="{ item }">
            {{ item.fullName }}
          </template>
          <template v-slot:from="{ item }">
            {{ $_date(item.fromTime * 1000, "long") }}
          </template>
          <template v-slot:to="{ item }">
            {{ $_date(item.toTime * 1000, "month") }}
          </template>
          <template v-slot:type="{ item }">
            {{ $_t(`attribute.${item.settlementType}`) }}
          </template>
          <template #baseType="{ item }">
            {{
              item.teacherLessonType
                ? $_t(`attribute.${item.teacherLessonType}`)
                : ""
            }}
          </template>
          <!--          <template v-slot:detail="{ item }">-->
          <!--            <v-btn-->
          <!--              color="primary"-->
          <!--              v-if="item.classroomsDetail.length"-->
          <!--              @click="showSetllement(item.classroomsDetail)"-->
          <!--            >-->
          <!--              {{ $_t("attribute.details") }}-->
          <!--            </v-btn>-->
          <!--          </template>-->
          <template v-slot:description="{ item }">
            <ShowLessonDescription
              v-if="item.settlementDescription"
              :description="item.settlementDescription"
            />
          </template>
          <template v-slot:edit="{ item }">
            <v-btn icon color="primary" @click="editSettlement(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:delete="{ item }">
            <v-btn
              @click="openDeleteSettlement(item)"
              :disabled="item.amount !== 0"
              icon
              color="red"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="init({})"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
    <v-dialog v-model="showSettlementDetails" width="800">
      <template v-if="details">
        <v-card>
          <v-card-title v-for="(d, index) in details" :key="index">
            {{ `${$_t("attribute.classroomName")}: ${d.classroomName}` }}
            <v-row>
              <v-col cols="12">
                {{
                  `${$_t("attribute.baseType")}: ${$_t(
                    `attribute.${d.courseName}`
                  )}`
                }}
              </v-col>
              <v-col cols="12">
                {{ `${$_t("attribute.classroomType")}: ${d.classroomType}` }}
              </v-col>
              <v-col cols="12">
                {{ `${$_t("attribute.teachHour")}: ${d.teachHour}` }}
              </v-col>
              <v-col cols="12">
                {{ `${$_t("attribute.teachFee")}: ${d.teachFee}` }}
              </v-col>
              <v-col cols="12" v-if="role !== 'TeamLeader'">
                {{
                  `${$_t("attribute.studentsTotalPayForClassroom")}: ${
                    d.studentsTotalPayForClassroom
                  }`
                }}
              </v-col>
              <v-col cols="12" v-if="role !== 'TeamLeader'">
                {{ "-" + $_t("attribute.classroomStudents") + " :" }}
              </v-col>
              <v-col cols="12" v-if="role !== 'TeamLeader'">
                <v-card-text
                  v-for="(stu, index) in d.classroomStudents"
                  :key="index"
                  class="d-inline text-body-1"
                >
                  <div>
                    {{ `${$_t("attribute.fullName")}: ${stu.fullName}` }}
                    <br />
                    {{ `${$_t("attribute.payedAmount")}: ${stu.payedAmount}` }}
                    <br />
                  </div>
                </v-card-text>
              </v-col>
              <v-divider></v-divider>
            </v-row>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showSettlementDetails = false">
              {{ $_t("attribute.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDelete ref="deleteSettlement" @confirm="deleteSettlement">
    </ConfirmDelete>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { SETTLEMENT_TABLE_HEADER } from "@/packages/finance/schema/table/SETTLEMENT_TABLE_HEADER.schema";
import { utils, writeFileXLSX } from "xlsx";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import moment from "moment";

export default {
  name: "SettlementTable",
  components: { ConfirmDelete, ShowLessonDescription, MainTable, TopTitle },
  computed: {
    ...mapGetters({
      settlements: "settlement/settlements",
      is_loading: "loading/is_loading",
      role: "authentication/role",
      teachers: "admin/get_teachers",
    }),
  },
  data() {
    return {
      search: "",
      sumOfAll: 0,
      fromTo: [],
      menu: false,
      teacherPublicKey: null,
      teacherSearch: null,
      details: null,
      showSettlementDetails: false,
      date: null,
      menu2: false,
      settlement: null,
    };
  },
  setup() {
    const lessonType = ref(null);
    const baseType = ref(null);
    const headers = SETTLEMENT_TABLE_HEADER.headers;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
    };
  },
  methods: {
    async init({ search = this.search }) {
      this.search = search;
      const { pageCount } = await this.$actions.getSettlements({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        search: this.search,
        dispatch: "settlement/setSettlements",
        fromTo: this.fromTo,
        role: this.role,
        teacherPublicKey: this.teacherPublicKey,
      });
      this.sumOfAll = await this.$actions.getSumOfAllTeachersSettlement();
      this.pageInfo.pageCount = pageCount;
      this.menu = false;
    },
    editSettlement(item) {
      this.$emit("edit", item);
    },
    deleteSettlement() {
      this.$emit("delete", this.settlement);
    },
    openDeleteSettlement(item) {
      this.settlement = item;
      this.$refs.deleteSettlement.open();
    },
    async fetchTeachers() {
      await this.$actions.getUsers({
        all: true,
        role: "Teacher",
        search: this.teacherSearch,
        dispatch: "admin/setTeachers",
      });
    },
    async exportSettlements() {
      const { settlements } = await this.$actions.getSettlements({ all: true });
      const list = settlements.map((s) => {
        return {
          type: s.settlementType,
          teacherName: s.teacherFullName,
          amount: s.amount,
          from: this.$_date(s.fromTime * 1000, "long"),
          to: this.$_date(s.toTime * 1000, "long"),
          totalHours: s.totalTeachHour,
          studentsTotalPayForClass: s.studentsTotalPayForToTime,
          details: s.classroomsDetail
            .map((d) => `${d.classroomName} ${d.classroomType}`)
            .join(", "),
        };
      });
      const ws = utils.json_to_sheet(list);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `settlements | ${this.$_date(new Date(), "ISO")}.xlsx`);
    },
    showSetllement(list) {
      this.details = list;
      this.showSettlementDetails = true;
    },
    async exportFinancialGeneral() {
      let config = { year: null, month: null };
      if (this.date) {
        config.year = moment(this.date).year();
        config.month = moment(this.date).month() + 1;
      }
      await api
        .get(urls.qs("exportFinancialGeneralReport", config), {
          responseType: "blob",
        })
        .then((res) => {
          FileDownload(
            res,
            `${
              this.date ? this.date + " - " : "total"
            } Financial general report.xlsx`
          );
        });
    },
  },
  created() {
    this.init({});
    this.fetchTeachers();
  },
};
</script>
