//ADD_SETTLEMENT

import { _t } from "@/tools/Utils";
import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";

export const ADD_SALARY = {
  /* Form SCHEMA */
  schema: [
    {
      id: "settlementType",
      label: "settlementType",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      show: true,
      text: "text",
      value: "value",
      items: [
        { text: _t("attribute.otherPayments"), value: "OtherPayments" },
        { text: _t("attribute.teachingSalary"), value: "TeachingSalary" },
      ],
      type: "select",
    },
    {
      id: "teacherLessonType",
      label: "teacherLessonType",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      show: true,
      text: "name",
      value: "value",
      items: [...lessons_array],
      type: "select",
    },
    {
      id: "teacher",
      label: "teacher",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      show: true,
      readonly: true,
      type: "text",
    },
    {
      id: "fromTime",
      label: "fromTime",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      disabled: false,
      type: "datetime",
    },
    {
      id: "toTime",
      label: "toTime",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "datetime",
    },
    {
      id: "amount",
      label: "amount",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "settlementDescription",
      label: "settlementDescription",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      disabled: false,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    settlementType: null,
    fromTime: null,
    toTime: null,
    amount: null,
    teacher: null,
    settlementDescription: null,
    teacherLessonType: null,
  },
};
